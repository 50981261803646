import { defineComponent } from 'vue'
import { GfrGridItem } from '#components'
import './index.scss'
export default defineComponent({
  name: 'ArticleCard',
  props: {
    image: {
      type: String,
      default: ''
    },
    title: String,
    tag: String,
    url: String
  },
  setup(props) {
    const cardRender = () => (
      <>
        {props.image && (
          <picture class={'com-article-card-cover'}>
            <img class="com-article-card-img" src={props.image} alt={props.title} />
          </picture>
        )}
        <div class={'com-article-card-ctx'}>
          {props.title && <h3 class={'com-article-card-title'}>{props.title}</h3>}
          {props.tag && <span class={'com-article-card-tag'}>{props.tag}</span>}
        </div>
      </>
    )
    return () => (
      <GfrGridItem class={'com-article-card'} url={props.url}>
        {cardRender()}
      </GfrGridItem>
    )
  }
})
